<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="selectForm">
          <v-select
            v-model="selectedOption"
            :items="options"
            outlined
            :rules="required ? [(val) => !!val] : []"
            label="Seleccione una opción"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirm">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    options: Array,
    title: String,
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      selectedOption: null,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      if (this.required) {
        if (this.$refs.selectForm.validate()) {
          this.resolve(this.selectedOption);
          this.dialog = false;
        }
      } else {
        this.reject(new Error("No option selected"));
        this.dialog = false;
      }
    },
  },
};
</script>
