import { requestFile } from "@/services/file.js";

export default {
  methods: {
    disabledUpload(document) {
      const checkAdjuntar = document?.adjunta_documento;
      if (typeof checkAdjuntar == "undefined") {
        return false;
      }
      return !checkAdjuntar;
    },
    downloadFile(document) {
      document.loading = true;
      requestFile(document?.url_archivo ?? "")
        .then(async (response) => {
          const url = response.data.url;
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Abriendo archivo...",
            top: true,
            right: true,
            timeout: 2000,
            color: "info",
          });
          await setTimeout(() => {
            window.open(url, "_blank");
          }, 1500);
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          document.loading = false;
        });
    },
  },
};
